<template>
  <div class="philosophy">
	<div class="close-icon" @click="closePage">
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><line class="cls-1" x1="3" y1="2" x2="46" y2="45"/><line class="cls-1" x1="2" y1="46" x2="45" y2="3"/></g></g></svg>
	</div>
  	<div class="philosophy-copy">
  		<div class="copy-container">
  			<h1 class="animated fadeIn">Our Purpose</h1>
	  		<div class="spacer-sm"></div>
	  		<p class="animated fadeIn" style="max-width: 450px; margin: 0 auto;">Blood UTD was founded on the belief that some ideas are so powerful they can unite you like blood. </p>
	  		<div class="spacer-sm"></div>
	  		<p class="animated fadeIn">It is our role to find those ideas for you. To create a deeper emotional connection between you and your audience. To make work that is culturally relevant, giving you a clear purpose and place in the world. And ultimately helping you to unlock the hearts of new fans to grow your brand.</p>
  		</div>
	  	</div>
  	</div>
  </div>
</template>

<script>

export default {
  name: 'our-purpose',
  methods: {
  	closePage: function() {
  		this.$router.go(-1)
  	}
  }
}
</script>

<style lang="scss" scoped>
.philosophy {

	display: flex;
	align-items: center;
	justify-content: center;
	height: calc(100% - 140px);

    @media(min-width: 680px) {
		height: calc(100% + 100px);
    	margin-top: -100px;
    	margin-bottom: -100px;
    	margin-left: -100px;

    }

	@media(max-width: 680px) {
		padding: 40px 40px;
		align-items: flex-start;
	}

	.philosophy-copy {
		max-width: 560px;
		text-align: center;
		color: #f9f9f9;
		h1 {
			font-weight: 400;
			font-size: 1.6em;
			font-family: AkzidenzGrotesk-BoldExtended;
		}
		p {
			line-height: 30px;
			margin: 5px 0px;

			@media(min-width: 680px) {
				font-family: AkzidenzGrotesk-Medium;
			}
		}
		a {
			color: #f9f9f9;
			text-decoration: none;
			display: block;
			margin: 5px 0px;
		}
		@media(max-width: 680px) {
			text-align: center;

			a {
				color: #373737;
			}
		}
	}

	.close-icon {
	    position: absolute;
	    top: 30px;
	    right: 100px;
	    font-size: 14px;
	    cursor: pointer;
	    width: 40px;
	    height: 40px;
	    z-index: 5;

	    @media(max-width: 680px) {
			display: none;
		}

	    svg {
	      width: 25px;
	    }
		.cls-1 {fill:none;stroke:#fff;stroke-linecap:round;stroke-miterlimit:10;stroke-width:4px;}
	}
}
</style>
